<template>
    <div :class="$style.info">
        <div :class="$style.header">
            {{ $t('cookies.title') }}
        </div>
        <div :class="$style.content">
            {{ $t('cookies.content') }}
        </div>
        <div :class="$style.accept">
            <button :class="$style.button" @click="onAccept">
                {{ $t('cookies.accept') }}
            </button>
        </div>
        <div :class="$style.settings">
            <button
                :class="[$style.button, $style.buttonLink]"
                @click="onSettings"
            >
                {{ $t('cookies.settings') }}
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { CookiesResource } from '@/api/resources/CookiesResource'
import GtmConsentsMixin from '@/mixins/GtmConsentsMixin'
import UuidMixin from '@/mixins/UuidMixin'

export default {
    mixins: [GtmConsentsMixin, UuidMixin],
    data() {
        return {
            types: new CookiesResource(),
        }
    },
    computed: {
        ...mapGetters({
            cookiesTypes: 'getTypes',
            sessionId: 'getSessionId'
        }),
    },
    mounted() {
        this.$nextTick(() => {
            this.types = new CookiesResource(this.cookiesTypes)
        })
    },
    methods: {
        ...mapMutations({
            setCookiesTypes: 'setTypes',
            setSessionId: 'setSessionId'
        }),
        onAccept() {
            const uuid = this.sessionId ? this.sessionId : this.create_UUID()
            this.setCookiesTypes(
                new CookiesResource({
                    necessaryConsent: true,
                    functionalConsent: true,
                    analyticConsent: true,
                    advertisingConsent: true,
                })
            )
            this.setSessionId(uuid)
            this.$cookies.set(
                'cookiesTypes',
                {
                    necessaryConsent: true,
                    functionalConsent: true,
                    analyticConsent: true,
                    advertisingConsent: true,
                },
                60 * 60 * 24 * 365,
                '/',
                process.env.VUE_APP_COOKIES_DOMAIN,
                true,
                'None'
            )
            this.$cookies.set(
                'cookiesAcceptedUuid',
                uuid,
                60 * 60 * 24 * 365,
                '/',
                process.env.VUE_APP_COOKIES_DOMAIN,
                true,
                'None'
            )
            this.pushDataLayer('consent', 'update', {
                ad_storage: 'granted',
                ad_user_data: 'granted',
                ad_personalization: 'granted',
                analytics_storage: 'granted',
                functionality_storage: 'granted',
                personalization_storage: 'granted',
                security_storage: 'granted',
            })
            this.pushDataLayerEvent('cookie_consent_update')
            this.$emit('onAccept')
        },
        onSettings() {
            this.$emit('onSettings')
        },
    },
}
</script>

<style lang="scss" module>
.info {
    max-width: 348px;

    .header,
    .content,
    .accept {
        margin-bottom: 24px;
    }
    .header {
        font-weight: 700;
        font-size: 1.8rem;
    }
    .content {
        font-size: 1.6rem;
    }
    .button {
        display: block;
        text-align: center;
        width: 100%;
        cursor: pointer;
        color: #ffffff;
        background-color: #ed544e;
        border: unset;
        font-size: 1.8rem;
        padding: 13px 26px;
        transition: background-color 0.3s;

        &:hover {
            background-color: #2f3031;
        }

        &Link {
            color: #000000;
            background-color: unset;
            font-size: 1.6rem;
            padding: 0;
        }
    }

    .settings {
        .button {
            background: unset;
            border: none;
        }
    }

    @media (max-width: 800px) {
        max-width: 100%;
    }
}
</style>
