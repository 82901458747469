<template>
    <label
        :class="[
            $style.inputCheckbox,
            required ? $style.inputCheckboxRequired : '',
            isError ? $style.inputCheckboxError : '',
            disabled ? $style.inputCheckboxDisabled : '',
        ]"
        @click.stop.prevent="onCheck"
    >
        <input :disabled="disabled" :checked="isChecked" type="checkbox" />
        <span :class="$style.checkmark">
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" :class="$style.icon">
                <path d="M10.1198 18.2262L4.78516 12.8916L5.85438 11.8224L10.1198 16.0877L19.2851 6.92236L20.3544 7.99156L10.1198 18.2262Z"></path>
            </svg>
        </span>
        <span
            v-if="label"
            :class="$style.inputCheckboxLabel"
        >
            <span v-html="label" />
        </span>
    </label>
</template>

<script>
export default {
    model: {
        prop: 'modelValue',
        event: 'change',
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        modelValue: {
            type: [Array, Boolean],
            required: false,
            default: () => [],
        },
        value: {
            type: [Boolean, String, Number, Object],
            required: false,
            default: false,
        },
        valueReferenceProp: {
            type: String,
            required: false,
            default: 'id',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            checked: false,
            isError: false,
        }
    },
    computed: {
        isChecked() {
            if (!Array.isArray(this.modelValue)) {
                return this.modelValue
            }
            if (typeof this.value !== 'object') {
                return this.modelValue.includes(this.value)
            }
            return !!this.modelValue.find(
                (item) =>
                    item[this.valueReferenceProp] ===
                    this.value[this.valueReferenceProp]
            )
        },
    },
    created() {
        if (!Array.isArray(this.modelValue)) {
            return
        }
        if (typeof this.value !== 'object') {
            return
        }
        if (
            !Object.prototype.hasOwnProperty.call(
                this.value,
                this.valueReferenceProp
            )
        ) {
            console.error(
                `\nPROPERTY: "${this.valueReferenceProp}" DOES NOT EXIST ON
                 \nVALUE:\n${JSON.stringify(this.value)}`
            )
        }
    },
    methods: {
        onCheck($event) {
            if (this.disabled) {
                return
            }
            if ($event.target.tagName.toLowerCase() === 'a') {
                if (!window) {
                    return
                }
                window.open($event.target.href)
                return
            }
            if (Array.isArray(this.modelValue)) {
                const newValue = [...this.modelValue]
                if (!this.isChecked) {
                    newValue.push(this.value)
                } else {
                    newValue.splice(newValue.indexOf(this.value), 1)
                }
                this.$emit('change', newValue)
            } else {
                this.$emit('change', !this.modelValue)
            }
        },
        validate() {
            const valid = this.validRequired()
            this.isError = !valid
            return valid
        },
        validRequired() {
            return !(this.required && !this.modelValue)
        },
    },
}
</script>

<style lang="scss" module>
.inputCheckbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    &.alignTop {
        align-items: flex-start;

        .checkmark {
            margin-top: 5px;
        }
    }

    input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;

        &:checked ~ .checkmark {
            .icon {
                opacity: 1;
            }

            &:after {
                opacity: 1;
            }
        }
    }

    &Label {
        width: 100%;
        padding: 0 22px;
        position: relative;

        a {
            text-decoration: underline;
        }
    }

    .checkmark {
        background-color: #fff;
        border: #222 solid 1px;
        width: 24px;
        min-width: 24px;
        min-height: 24px;
        height: 24px;
        position: relative;
        left: 0;

        .icon {
            opacity: 0;
            position: absolute;
            transition: all 0.2s ease-out;
            top: 50%;
            left: 50%;
            fill: #ed544e;
            transform: translate(-50%, -50%);
        }
    }

    &Error {
        .inputCheckboxLabel {
            color: #ed544e;
        }
        .checkmark {
            border-color: #ed544e;
        }
    }

    &Disabled {
        cursor: default;
        opacity: 0.4;
    }

    @media (max-width: 1675px) {
        .checkmark {
            width: 16px;
            height: 16px;
        }
    }
}
</style>
