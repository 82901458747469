export default {
    methods: {
        pushDataLayer() {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push(arguments)
        },
        pushDataLayerEvent(event, body) {
            try {
                if (!window) {
                    console.warn('trying access `window` on SSR! ', event)
                    return
                }

                const dl = {
                    event,
                    ...body,
                }

                window.dataLayer.push(dl)
            } catch (e) {
                console.error(e)
            }
        },
    },
}
