<template>
    <div class="layout-default">
        <Navigation />
        <Workspace />
        <LoaderModal />
        <WorkspaceVirtual />
        <CookiesWrapper />
    </div>
</template>

<script>
import Navigation from '@/components/navigation/Navigation'
import Workspace from '@/components/workspace/Workspace'
import WorkspaceVirtual from '@/components/workspace/WorkspaceVirtual'
import LoaderModal from '@/components/loader/LoaderModal.vue'
import CookiesWrapper from '@/components/cookies/CookiesWrapper'


export default {
    components: {
        LoaderModal,
        Navigation,
        Workspace,
        WorkspaceVirtual,
        CookiesWrapper
    },
}
</script>

<style lang="scss">
.layout-default {
    position: relative;
    display: flex;
    height: 100%;
    overflow: hidden;
}
</style>
