import { render, staticRenderFns } from "./CookiesPopup.vue?vue&type=template&id=827dd138&scoped=true&"
import script from "./CookiesPopup.vue?vue&type=script&lang=js&"
export * from "./CookiesPopup.vue?vue&type=script&lang=js&"
import style0 from "./CookiesPopup.vue?vue&type=style&index=0&id=827dd138&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "827dd138",
  null
  
)

export default component.exports